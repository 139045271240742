import { useState } from 'react';
import './app.scss'
import einzelkarten from './karten.json'
import { mischen } from './helpers';
import classNames from 'classnames';

function App() {
  const [alleKarten] = useState(mischen(einzelkarten.concat(
    einzelkarten
  )));
  const [offeneKarten, offeneKartenSetzen] = useState([]);
  const [gefundeneKarten, gefundeneKartenSetzen] = useState([]);

  const [anzahlZuege, anzahlZuegeSetzen ] = useState(0);
  const [highscore, highscoreSetzen ] = useState(localStorage.getItem('highScore') || 0);

    const spielNeustarten = (useConfirmation = true) => {
      if (useConfirmation) {
        const confirmation = window.confirm('Möchtest du wirklich neu starten?');
        if (!confirmation) return;
      }

      window.location.reload();
    };

  const kartenKlick = (cardIndex) => {
    if (prüfeUmgedreht(cardIndex) || prüfeGefunden(alleKarten[cardIndex])) return;

    const [first] = offeneKarten;

    // Erste Karte gedreht
    if (!offeneKarten.length) {
      offeneKartenSetzen([cardIndex]);
      // Zweite Karte gedreht
    } else if (offeneKarten.length === 1) {
      offeneKartenSetzen([...offeneKarten, cardIndex])
      // Falls die Karten gleich sind
      if (alleKarten[first].name === alleKarten[cardIndex].name) {
        const newFoundCardNames = [...gefundeneKarten, alleKarten[cardIndex].name];
        setTimeout(() => {
          gefundeneKartenSetzen(newFoundCardNames);
        }, 500);
        
        // Falls gewonnen
        if (newFoundCardNames.length === einzelkarten.length) {
          // Highscore updaten
          highscoreSetzen(Math.min(anzahlZuege + 1, highscore ? highscore : anzahlZuege + 1));
          // LocalStorage updaten
          localStorage.setItem('highScore', anzahlZuege + 1);
          
          setTimeout(() => {
            const restart = window.confirm(`Du hast gewonnen mit ${anzahlZuege +1} zügen! Möchtest du neu starten?`);
            restart && spielNeustarten(false);
          }, 500*2);
        }
      }

      anzahlZuegeSetzen((anzahlZuege) => anzahlZuege + 1);

      setTimeout(() => {
        offeneKartenSetzen([]);
      }, 500);
    }
  }

  const prüfeUmgedreht = (index) => {
    return offeneKarten.includes(index);
  };

  const prüfeGefunden = (card) => {
    return Boolean(gefundeneKarten.includes(card.name));
  };

  return (
    <div className='fussball'>
      <header>
        <h1>Memory game</h1>
      </header>
      <main>
        <div className="kartenrahmen">
          {alleKarten.map((karte, index) => (
            // Karten Inhalt
            <div key={index} className={classNames("karte", {
              umgedreht: prüfeUmgedreht(index),
              gefunden: prüfeGefunden(karte)
            })} onClick={() => kartenKlick(index)}>
              <div className="oberfläche rückseite">
                <img src={karte.bild}className='kartenbild' alt="" />
              </div>
              <div className="oberfläche vorderseite">
                <img src="/karten/Fussball.png" className='kartenbild' alt="" />
              </div>
            </div>
          )
          )}
        </div>
      </main>
      <footer>
        <div className="zuege">Züge: {anzahlZuege}</div>
        <div className="rekord">Highscore: {highscore}</div>
        <button onClick={spielNeustarten}>Neustart</button>
        Von Amelia Steck
      </footer>
    </div>
  );
};

export default App;
